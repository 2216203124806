#stats-table {
  border-collapse: collapse;
  width: 80%;
  margin: 1% auto;
}

.stats-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stats-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stats-summary h2 {
  margin-right: 20px; /* Add some spacing between the title and other elements */
}

h2 {
  text-align: center; /* Aligns the text horizontally to the center */
  margin-top: 5%; /* Adds margin to the top */
}

#stats-table th, #stats-table td {
  border: 1px solid #000;
  padding: 8px;
  text-align: center;
}

#stats-table th {
  background-color: #000;
  color: #fff;
  text-align: center;
}

.positive {
  color: green;
}

.negative {
  color: red;
}
