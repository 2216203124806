.login-container {
  max-width: 300px;
  margin: auto;
  padding: 20px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-heading {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  display: block;
  padding: 10px;
  font-size: 16px;
  border: 1px solid black;
  border-radius: 5px;
}

.btn {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #000;
  color: #fff;
  cursor: pointer;
}

.btn:hover {
  background-color: #333;
}
